let wakeLock: any = null;
let audio: HTMLAudioElement | null = null;

export const requestWakeLock = async () => {
  try {
    if ('wakeLock' in navigator) {
      const nav: any = navigator;
      wakeLock = await nav.wakeLock.request('screen');
      console.log('Wake Lock is active');
    } else {
      console.warn('Wake Lock API is not supported in this browser. Using audio fallback.');
      audio = new Audio('data:audio/wav;base64,...'); // Base64 encoded silent audio file
      audio.loop = true;
      await audio.play();
    }
  } catch (err) {
    console.error(err);
  }
};

export const releaseWakeLock = async () => {
  if (wakeLock !== null) {
    try {
      await wakeLock.release();
      wakeLock = null;
      console.log('Wake Lock is released');
    } catch (err) {
      console.error(err);
    }
  }
  if (audio !== null) {
    audio.pause();
    audio = null;
    console.log('Audio fallback is stopped');
  }
};