import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppProvider from '@shared/contexts/AppContext';
import MainTheme from '@shared/themes/main';
import { RouterHistoryService as history } from '@shared/core';
import App from './containers/App/App';
import LanguageProvider from '@shared/containers/LanguageProvider';
import * as serviceWorker from './serviceWorker';
import { getTranslationMessages } from './i18n';
import './vendor';
import { LOCALSTORAGE_CURRENTCOMPANY, LOCALSTORAGE_LANGUAGES } from './assets/constants/contants';
import { requestWakeLock, releaseWakeLock } from './wakeLock';

if (module.hot) {
  module.hot.accept();
}

const renderApp = async () => {
  const messages = await getTranslationMessages();
  const comp = localStorage.getItem(LOCALSTORAGE_CURRENTCOMPANY);
  const companyId = comp ? JSON.parse(comp)['company_id'] : '';
  const localeString = LOCALSTORAGE_LANGUAGES + companyId;
  render(
    <ThemeProvider theme={MainTheme}>
      <LanguageProvider messages={messages} localStorageLanguageString={localeString}>
        <AppProvider>
          <Router history={history}>
            <App />
          </Router>
        </AppProvider>
      </LanguageProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );

  // Request wake lock
  await requestWakeLock();
};

renderApp();

serviceWorker.register({});

// Release wake lock when the app is unloaded
window.addEventListener('unload', releaseWakeLock);
